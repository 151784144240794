@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css?family=Playfair+Display:400,400i,700,700i|Raleway:300,300i,400,400i,500,500i,600,600i,700,700i');
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300&display=swap');

* {
    /*box-sizing: border-box;*/
    margin: 0;
    padding: 0;
    font-family: 'Rubik', sans-serif;
}
body {
    background-color: #f6fafd;
    /*font-size: 14px;*/
    /*line-height: 22px;*/
}
.contact-us, .sign-up {
    display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}
