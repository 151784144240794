:root {
    --primary: #1888ff;
}

.btn {
    padding: 8px 20px;
    border-radius: 4px;
    outline: none;
    border: none;
    font-size: 15px;
    color: #fff;
    cursor: pointer;
    background-color: var(--primary);
    margin-right: 10px;
}

.btn:hover {
    padding: 6px 18px;
    transition: all 0.3s ease-out;
    background-color: transparent;
    color: var(--primary);
    border-radius: 4px;
    border: 2px solid var(--primary);
}
