.post-job-bg {
    height: 200px;
    background: url(../../assets/findJobs.jpg) no-repeat 100% center/cover;
    position: relative;
}

.post-job-bg::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #1d2c3e;
    opacity: 0.8;
}

.post-job {
    position: absolute;
    left: 10%;
    top: 10%;
    color: white;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 36px;
}