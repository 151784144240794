@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css?family=Playfair+Display:400,400i,700,700i|Raleway:300,300i,400,400i,500,500i,600,600i,700,700i);
@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@300&display=swap);
* {
    /*box-sizing: border-box;*/
    margin: 0;
    padding: 0;
    font-family: 'Rubik', sans-serif;
}
body {
    background-color: #f6fafd;
    /*font-size: 14px;*/
    /*line-height: 22px;*/
}
.contact-us, .sign-up {
    display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

:root {
    --primary: #1888ff;
}

.btn {
    padding: 8px 20px;
    border-radius: 4px;
    outline: none;
    border: none;
    font-size: 15px;
    color: #fff;
    cursor: pointer;
    background-color: #1888ff;
    background-color: var(--primary);
    margin-right: 10px;
}

.btn:hover {
    padding: 6px 18px;
    transition: all 0.3s ease-out;
    background-color: transparent;
    color: #1888ff;
    color: var(--primary);
    border-radius: 4px;
    border: 2px solid #1888ff;
    border: 2px solid var(--primary);
}

.navbar {
    background: #1c2237;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
  }
  
  .navbar-logo {
    color: #fff;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
  }
  
  .fa-firstdraft {
    margin-left: 0.5rem;
    font-size: 1.6rem;
  }
  
  .nav-menu {
    display: grid;
    grid-template-columns: repeat(9, auto);
    /*grid-gap: 10px;*/
    list-style: none;
    text-align: center;
    width: 70vw;
    justify-content: end;
  }
  
  .nav-item {
    display: flex;
    align-items: center;
    /*height: 80px;*/
    /*margin: auto 4px;*/
  }
  .nav-btn {
    margin: auto 4px;
  }
  .nav-item-mobile {
    margin: 6px auto;
    width: 100%;
  }
  .nav-links {
    color:#fff;
    text-decoration: none;
    padding: 0.5rem 1rem;
  }
  
  .nav-links:hover {
    background-color: #fff;
    color: #1c2237;
    border-radius: 4px;
    transition: all 0.2s ease-out;
  }
  
  .fa-bars {
    color: #fff;
  }
  
  .nav-links-mobile {
    display: none;
  }
  
  .menu-icon {
    display: none;
  }
  
  @media screen and (max-width: 960px) {
    .NavbarItems {
      position: relative;
    }
  
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 75vh;
      position: absolute;
      top: 80px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
    }
  
    .nav-menu.active {
      background: #fff;
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 2;
    }
  
    .nav-links {
      text-align: center;
      color: #1c2237;
      padding: 2rem;
      width: 100%;
      display: table;
    }
  
    .nav-items:hover {
      background-color: #1c2237;
      color: #fff;
      border-radius: 0;
    }
  
    .navbar-logo {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(25%, 50%);
    }
  
    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
    }
  
    .fa-times {
      color: #fff;
      font-size: 2rem;
    }
  
    .nav-links-mobile {
      display: block;
      text-align: center;
      padding: 1.5rem;
      margin: auto auto;
      border-radius: 4px;
      width: 80%;
      background: #1888ff;
      text-decoration: none;
      color: #fff;
      font-size: 1.5rem;


    }
  
    .nav-links-mobile:hover {
      background: #fff;
      color: #1888ff;
      transition: 250ms;
    }
  
    .nav-btn{
      display: none !important;
    }
  }

.dropdown-menu {
    background: red;
    width: 200px;
    position: absolute;
    top: 80px;
    list-style: none;
    text-align: start;
    margin-top: 0;
  }
  
  .dropdown-menu li {
    background: #fff;
    cursor: pointer;

  }
  
  .dropdown-menu li:hover {
    background: lightgrey;
    color: #1c2237;

  }
  
  .dropdown-menu.clicked {
    display: none;
  }
  
  .dropdown-link {
    display: block;
    height: 100%;
    width: 100%;
    text-decoration: none;
    color: #1c2237;
    padding: 16px;

  }

  .dropdown-link:hover {
      color: #1c2237;
  }
  
  @media screen and (max-width: 960px) {
    .fa-caret-down {
      display: none;
    }
  }
.footer-div {
    margin-top: 15px;
    background: #1c2237;
    text-align: center;
    color: #fff;
    padding: 8px;
    padding-top: 30px;
}

.GoogleSignInBtn_google-btn__2-6jq{width:100%;height:42px;background-color:#4285f4;border-radius:2px;box-shadow:0 3px 4px 0 rgba(0,0,0,0.25);display:flex;margin-top:10px;justify-content:left}.GoogleSignInBtn_google-btn__2-6jq .GoogleSignInBtn_google-icon-wrapper__2sSHv{margin-top:1px;margin-left:1px;width:40px;height:40px;border-radius:2px;background-color:#fff}.GoogleSignInBtn_google-btn__2-6jq .GoogleSignInBtn_google-icon__1yfud{margin-top:11px;margin-left:11px;width:18px;height:18px}.GoogleSignInBtn_google-btn__2-6jq .GoogleSignInBtn_btn-text__1W2Wi{width:100%;text-align:center;margin:11px 11px 0 0;color:#fff;font-size:14px;letter-spacing:0.2px}.GoogleSignInBtn_google-btn__2-6jq:hover{box-shadow:0 0 6px #4285f4}.GoogleSignInBtn_google-btn__2-6jq:active{background:#1669F2}

.notfound_container__1M5j5 {
    min-height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.home__hero-section {
    color: #fff;
  }

  .home__hero-text-wrapper {
    max-width: 540px;
    padding-top: 38vh;
    padding-bottom: 60px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
  }
  
  .img-employee {
    height: 700px; 
    background: url("/static/media/scott-graham-5fNmWej4tAA-unsplash - Copy.d718ce93.jpg") no-repeat 100% center/cover;
    position: relative;
  }

  .launching-line {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 19%;
    color: #3CBF71;
    font-size: 2rem;
    /* line-height: 16px; */
    font-weight: 700;
    letter-spacing: 1.4px;
    z-index: 1;
    width: 100%;
    text-align: center;
    font-family: 'Poppins';
    text-shadow: 1px 1px white;
    background: rgba(255,255,255,0.2);
  }

  .tag-line {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 28%;
    color: #0aaf4c;
    font-size: 1.2rem;
    /* font-weight: bolder; */
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 1.4px;
    z-index: 1;
    width: 100%;
    text-align: center;
    font-family: 'Montserrat';
    text-shadow: 1px 1px white;
  }

  .img-employee::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    display: block;
    background: #1d2c3e;
    opacity: 0.8;
  }

  .img-employer {
    height: 700px; 
    background: url("/static/media/work-with-island-FX2QA0TMEYg-unsplash - Copy.716a7308.jpg") no-repeat 100% center/cover;
    position: relative;
  }

  .img-employer::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    display: block;
    background:-webkit-linear-gradient(left, rgba(20,145,222,1) 0%,rgba(185,57,208,1) 100%);;
    opacity: 0.8;
  }

  .MuiGrid-spacing-xs-2 {
    width: 100% !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .MuiGrid-spacing-xs-2 > .MuiGrid-item {
    padding-left: 0px !important; 
    padding-right: 0px !important;
}

  .top-line {
    color: #f00946;
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    margin-bottom: 16px;
  }
  
  .heading {
    margin-bottom: 24px;
    font-size: 48px;
    line-height: 1.1;
    font-weight: 600;
    color: #fff;
    text-align: center;
  }
  
  .dark {
    color: #1c2237;
  }
  
  .darkBg {
    background-color: transparent;
  }
  
  .home__hero-subtitle {
    max-width: 440px;
    margin-bottom: 35px;
    font-size: 18px;
    line-height: 24px;
  }
  
  .home__hero-img-wrapper {
   margin: 0;
  }
  
  .home__hero-img {
    height: 700px;
    width: 100%;
    margin: 0;
    opacity: 0.5;
  }
  
  img {
    border: 0;
    max-width: 100%;
    vertical-align: middle;
    display: inline-block;
  }
  
  @media screen and (max-width: 768px) {
    .MuiGrid-spacing-xs-2 > .MuiGrid-item {
      padding : 0 !important;
    }

    .img-employee {
      height: 550px;
    }

    .img-employer {
      height: 550px;
    }

    /* .heading {
      font-size: 2rem;
    } */
    .launching-line {
      top: 18vh;
    }

    .tag-line {
      top: 28vh;
    }

    .home__hero-text-wrapper {
      padding-top: 25vh;
      padding-left: 1rem;
      padding-right: 1rem;
    } 

  }
  

.home__how-it-works {
    text-align: center;
}

.home__how-it-works__cards {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}
.home__section {
    padding-bottom: 40px;
}
.home__section__heading {
    padding: 50px;
}
.home__section h1 {
    position: relative;
    padding: 0;
    margin: 0;
    font-family: "Raleway", sans-serif;
    font-weight: 300;
    font-size: 40px;
    color: #080808;
    transition: all 0.4s ease 0s;
}
.home__section h1 {
    text-align: center;
    text-transform: uppercase;
    padding-bottom: 5px;
}
.home__section__heading h1:before {
    width: 28px;
    height: 5px;
    display: block;
    content: "";
    position: absolute;
    bottom: 3px;
    left: 50%;
    margin-left: -14px;
    background-color: RGB(28,34,55);
}
.home__section__heading h1:after {
    width: 100px;
    height: 1px;
    display: block;
    content: "";
    position: relative;
    margin-top: 25px;
    left: 50%;
    margin-left: -50px;
    background-color: RGB(28,34,55);
}


.home__card {
    background: #fff;
    border-radius: 4px;
    display: inline-block;
    height: 250px;
    margin: 1rem;
    position: relative;
    width: 200px;
    padding: 15px;
    text-align: center;
}


.home__card--shadow {
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px RGB(242,238,253);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}

.home__card--hover:hover {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px RGB(242,238,253);
}

.home__card--icon {
    color: RGB(108,67,231);
    padding-top: 50px;
    padding-bottom: 20px;
    font-size: 2em;
    text-align: center;
}
.home__card--heading {
    margin: 5px;
}
.home__card--text {
    font-weight: lighter;
}

.home__job-categories {
    text-align: center;
}

@media screen and (min-width: 960px) {
    .grid-spacing {
        padding-left: 13rem;
        padding-right: 13rem;
    }
}
/**{*/
/*    font-family: 'Montserrat', sans-serif;*/
/*}*/

.find-job-bg {
    height: 250px;
    background: url(/static/media/findJobs.e8b9aaf8.jpg) no-repeat 100% center/cover;
    position: relative;
}

.find-job-bg::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #1d2c3e;
    opacity: 0.8;
}

.search-section {
    position: absolute;
    bottom: 0;
    left: 0;
}

.browse-job {
    position: absolute;
    left: 20%;
    top: 10%;
    color: white;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 36px;
}

.MuiButton-label {
    font-family: 'Poppins', sans-serif;
}

.post-job-bg {
    height: 200px;
    background: url(/static/media/findJobs.e8b9aaf8.jpg) no-repeat 100% center/cover;
    position: relative;
}

.post-job-bg::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #1d2c3e;
    opacity: 0.8;
}

.post-job {
    position: absolute;
    left: 10%;
    top: 10%;
    color: white;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 36px;
}
