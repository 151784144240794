/**{*/
/*    font-family: 'Montserrat', sans-serif;*/
/*}*/

.find-job-bg {
    height: 250px;
    background: url(../../assets/findJobs.jpg) no-repeat 100% center/cover;
    position: relative;
}

.find-job-bg::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #1d2c3e;
    opacity: 0.8;
}

.search-section {
    position: absolute;
    bottom: 0;
    left: 0;
}

.browse-job {
    position: absolute;
    left: 20%;
    top: 10%;
    color: white;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 36px;
}

.MuiButton-label {
    font-family: 'Poppins', sans-serif;
}
