.home__card {
    background: #fff;
    border-radius: 4px;
    display: inline-block;
    height: 250px;
    margin: 1rem;
    position: relative;
    width: 200px;
    padding: 15px;
    text-align: center;
}


.home__card--shadow {
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px RGB(242,238,253);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}

.home__card--hover:hover {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px RGB(242,238,253);
}

.home__card--icon {
    color: RGB(108,67,231);
    padding-top: 50px;
    padding-bottom: 20px;
    font-size: 2em;
    text-align: center;
}
.home__card--heading {
    margin: 5px;
}
.home__card--text {
    font-weight: lighter;
}
