.navbar {
    background: #1c2237;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
  }
  
  .navbar-logo {
    color: #fff;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
  }
  
  .fa-firstdraft {
    margin-left: 0.5rem;
    font-size: 1.6rem;
  }
  
  .nav-menu {
    display: grid;
    grid-template-columns: repeat(9, auto);
    /*grid-gap: 10px;*/
    list-style: none;
    text-align: center;
    width: 70vw;
    justify-content: end;
  }
  
  .nav-item {
    display: flex;
    align-items: center;
    /*height: 80px;*/
    /*margin: auto 4px;*/
  }
  .nav-btn {
    margin: auto 4px;
  }
  .nav-item-mobile {
    margin: 6px auto;
    width: 100%;
  }
  .nav-links {
    color:#fff;
    text-decoration: none;
    padding: 0.5rem 1rem;
  }
  
  .nav-links:hover {
    background-color: #fff;
    color: #1c2237;
    border-radius: 4px;
    transition: all 0.2s ease-out;
  }
  
  .fa-bars {
    color: #fff;
  }
  
  .nav-links-mobile {
    display: none;
  }
  
  .menu-icon {
    display: none;
  }
  
  @media screen and (max-width: 960px) {
    .NavbarItems {
      position: relative;
    }
  
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 75vh;
      position: absolute;
      top: 80px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
    }
  
    .nav-menu.active {
      background: #fff;
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 2;
    }
  
    .nav-links {
      text-align: center;
      color: #1c2237;
      padding: 2rem;
      width: 100%;
      display: table;
    }
  
    .nav-items:hover {
      background-color: #1c2237;
      color: #fff;
      border-radius: 0;
    }
  
    .navbar-logo {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(25%, 50%);
    }
  
    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
    }
  
    .fa-times {
      color: #fff;
      font-size: 2rem;
    }
  
    .nav-links-mobile {
      display: block;
      text-align: center;
      padding: 1.5rem;
      margin: auto auto;
      border-radius: 4px;
      width: 80%;
      background: #1888ff;
      text-decoration: none;
      color: #fff;
      font-size: 1.5rem;


    }
  
    .nav-links-mobile:hover {
      background: #fff;
      color: #1888ff;
      transition: 250ms;
    }
  
    .nav-btn{
      display: none !important;
    }
  }
