.home__hero-section {
    color: #fff;
  }

  .home__hero-text-wrapper {
    max-width: 540px;
    padding-top: 38vh;
    padding-bottom: 60px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
  }
  
  .img-employee {
    height: 700px; 
    background: url(../../assets/scott-graham-5fNmWej4tAA-unsplash\ -\ Copy.jpg) no-repeat 100% center/cover;
    position: relative;
  }

  .launching-line {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 19%;
    color: #3CBF71;
    font-size: 2rem;
    /* line-height: 16px; */
    font-weight: 700;
    letter-spacing: 1.4px;
    z-index: 1;
    width: 100%;
    text-align: center;
    font-family: 'Poppins';
    text-shadow: 1px 1px white;
    background: rgba(255,255,255,0.2);
  }

  .tag-line {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 28%;
    color: #0aaf4c;
    font-size: 1.2rem;
    /* font-weight: bolder; */
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 1.4px;
    z-index: 1;
    width: 100%;
    text-align: center;
    font-family: 'Montserrat';
    text-shadow: 1px 1px white;
  }

  .img-employee::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    display: block;
    background: #1d2c3e;
    opacity: 0.8;
  }

  .img-employer {
    height: 700px; 
    background: url(../../assets/work-with-island-FX2QA0TMEYg-unsplash\ -\ Copy.jpg) no-repeat 100% center/cover;
    position: relative;
  }

  .img-employer::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    display: block;
    background:-webkit-linear-gradient(left, rgba(20,145,222,1) 0%,rgba(185,57,208,1) 100%);;
    opacity: 0.8;
  }

  .MuiGrid-spacing-xs-2 {
    width: 100% !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .MuiGrid-spacing-xs-2 > .MuiGrid-item {
    padding-left: 0px !important; 
    padding-right: 0px !important;
}

  .top-line {
    color: #f00946;
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    margin-bottom: 16px;
  }
  
  .heading {
    margin-bottom: 24px;
    font-size: 48px;
    line-height: 1.1;
    font-weight: 600;
    color: #fff;
    text-align: center;
  }
  
  .dark {
    color: #1c2237;
  }
  
  .darkBg {
    background-color: transparent;
  }
  
  .home__hero-subtitle {
    max-width: 440px;
    margin-bottom: 35px;
    font-size: 18px;
    line-height: 24px;
  }
  
  .home__hero-img-wrapper {
   margin: 0;
  }
  
  .home__hero-img {
    height: 700px;
    width: 100%;
    margin: 0;
    opacity: 0.5;
  }
  
  img {
    border: 0;
    max-width: 100%;
    vertical-align: middle;
    display: inline-block;
  }
  
  @media screen and (max-width: 768px) {
    .MuiGrid-spacing-xs-2 > .MuiGrid-item {
      padding : 0 !important;
    }

    .img-employee {
      height: 550px;
    }

    .img-employer {
      height: 550px;
    }

    /* .heading {
      font-size: 2rem;
    } */
    .launching-line {
      top: 18vh;
    }

    .tag-line {
      top: 28vh;
    }

    .home__hero-text-wrapper {
      padding-top: 25vh;
      padding-left: 1rem;
      padding-right: 1rem;
    } 

  }
  
