.home__how-it-works {
    text-align: center;
}

.home__how-it-works__cards {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}
.home__section {
    padding-bottom: 40px;
}
.home__section__heading {
    padding: 50px;
}
.home__section h1 {
    position: relative;
    padding: 0;
    margin: 0;
    font-family: "Raleway", sans-serif;
    font-weight: 300;
    font-size: 40px;
    color: #080808;
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
}
.home__section h1 {
    text-align: center;
    text-transform: uppercase;
    padding-bottom: 5px;
}
.home__section__heading h1:before {
    width: 28px;
    height: 5px;
    display: block;
    content: "";
    position: absolute;
    bottom: 3px;
    left: 50%;
    margin-left: -14px;
    background-color: RGB(28,34,55);
}
.home__section__heading h1:after {
    width: 100px;
    height: 1px;
    display: block;
    content: "";
    position: relative;
    margin-top: 25px;
    left: 50%;
    margin-left: -50px;
    background-color: RGB(28,34,55);
}

